/*=========================================
	++ common.js ++ Ver.1.5.3
	Last update 2019.5.7
==========================================*/
/*
* 2019  5/ 7  *object-fit-images 追加
* 2018 11/20  *ブラウザ判定処理を一新
* 2018 10/25  *jQuery 3系 に対応

*/
'usestrict';


//============================================
//  npm.im/object-fit-images 3.2.4
//  https://github.com/bfred-it/object-fit-images
//============================================

/*! npm.im/object-fit-images 3.2.4 */
var objectFitImages = (function () {
	'use strict';

	var OFI = 'bfred-it:object-fit-images';
	var propRegex = /(object-fit|object-position)\s*:\s*([-.\w\s%]+)/g;
	var testImg = typeof Image === 'undefined' ? {
		style: {
			'object-position': 1
		}
	} : new Image();
	var supportsObjectFit = 'object-fit' in testImg.style;
	var supportsObjectPosition = 'object-position' in testImg.style;
	var supportsOFI = 'background-size' in testImg.style;
	var supportsCurrentSrc = typeof testImg.currentSrc === 'string';
	var nativeGetAttribute = testImg.getAttribute;
	var nativeSetAttribute = testImg.setAttribute;
	var autoModeEnabled = false;

	function createPlaceholder(w, h) {
		return ("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + w + "' height='" + h + "'%3E%3C/svg%3E");
	}

	function polyfillCurrentSrc(el) {
		if (el.srcset && !supportsCurrentSrc && window.picturefill) {
			var pf = window.picturefill._;
			// parse srcset with picturefill where currentSrc isn't available
			if (!el[pf.ns] || !el[pf.ns].evaled) {
				// force synchronous srcset parsing
				pf.fillImg(el, {
					reselect: true
				});
			}

			if (!el[pf.ns].curSrc) {
				// force picturefill to parse srcset
				el[pf.ns].supported = false;
				pf.fillImg(el, {
					reselect: true
				});
			}

			// retrieve parsed currentSrc, if any
			el.currentSrc = el[pf.ns].curSrc || el.src;
		}
	}

	function getStyle(el) {
		var style = getComputedStyle(el).fontFamily;
		var parsed;
		var props = {};
		while ((parsed = propRegex.exec(style)) !== null) {
			props[parsed[1]] = parsed[2];
		}
		return props;
	}

	function setPlaceholder(img, width, height) {
		// Default: fill width, no height
		var placeholder = createPlaceholder(width || 1, height || 0);

		// Only set placeholder if it's different
		if (nativeGetAttribute.call(img, 'src') !== placeholder) {
			nativeSetAttribute.call(img, 'src', placeholder);
		}
	}

	function onImageReady(img, callback) {
		// naturalWidth is only available when the image headers are loaded,
		// this loop will poll it every 100ms.
		if (img.naturalWidth) {
			callback(img);
		} else {
			setTimeout(onImageReady, 100, img, callback);
		}
	}

	function fixOne(el) {
		var style = getStyle(el);
		var ofi = el[OFI];
		style['object-fit'] = style['object-fit'] || 'fill'; // default value

		// Avoid running where unnecessary, unless OFI had already done its deed
		if (!ofi.img) {
			// fill is the default behavior so no action is necessary
			if (style['object-fit'] === 'fill') {
				return;
			}

			// Where object-fit is supported and object-position isn't (Safari < 10)
			if (
				!ofi.skipTest && // unless user wants to apply regardless of browser support
				supportsObjectFit && // if browser already supports object-fit
				!style['object-position'] // unless object-position is used
			) {
				return;
			}
		}

		// keep a clone in memory while resetting the original to a blank
		if (!ofi.img) {
			ofi.img = new Image(el.width, el.height);
			ofi.img.srcset = nativeGetAttribute.call(el, "data-ofi-srcset") || el.srcset;
			ofi.img.src = nativeGetAttribute.call(el, "data-ofi-src") || el.src;

			// preserve for any future cloneNode calls
			// https://github.com/bfred-it/object-fit-images/issues/53
			nativeSetAttribute.call(el, "data-ofi-src", el.src);
			if (el.srcset) {
				nativeSetAttribute.call(el, "data-ofi-srcset", el.srcset);
			}

			setPlaceholder(el, el.naturalWidth || el.width, el.naturalHeight || el.height);

			// remove srcset because it overrides src
			if (el.srcset) {
				el.srcset = '';
			}
			try {
				keepSrcUsable(el);
			} catch (err) {
				if (window.console) {
					console.warn('https://bit.ly/ofi-old-browser');
				}
			}
		}

		polyfillCurrentSrc(ofi.img);

		el.style.backgroundImage = "url(\"" + ((ofi.img.currentSrc || ofi.img.src).replace(/"/g, '\\"')) + "\")";
		el.style.backgroundPosition = style['object-position'] || 'center';
		el.style.backgroundRepeat = 'no-repeat';
		el.style.backgroundOrigin = 'content-box';

		if (/scale-down/.test(style['object-fit'])) {
			onImageReady(ofi.img, function () {
				if (ofi.img.naturalWidth > el.width || ofi.img.naturalHeight > el.height) {
					el.style.backgroundSize = 'contain';
				} else {
					el.style.backgroundSize = 'auto';
				}
			});
		} else {
			el.style.backgroundSize = style['object-fit'].replace('none', 'auto').replace('fill', '100% 100%');
		}

		onImageReady(ofi.img, function (img) {
			setPlaceholder(el, img.naturalWidth, img.naturalHeight);
		});
	}

	function keepSrcUsable(el) {
		var descriptors = {
			get: function get(prop) {
				return el[OFI].img[prop ? prop : 'src'];
			},
			set: function set(value, prop) {
				el[OFI].img[prop ? prop : 'src'] = value;
				nativeSetAttribute.call(el, ("data-ofi-" + prop), value); // preserve for any future cloneNode
				fixOne(el);
				return value;
			}
		};
		Object.defineProperty(el, 'src', descriptors);
		Object.defineProperty(el, 'currentSrc', {
			get: function () {
				return descriptors.get('currentSrc');
			}
		});
		Object.defineProperty(el, 'srcset', {
			get: function () {
				return descriptors.get('srcset');
			},
			set: function (ss) {
				return descriptors.set(ss, 'srcset');
			}
		});
	}

	function hijackAttributes() {
		function getOfiImageMaybe(el, name) {
			return el[OFI] && el[OFI].img && (name === 'src' || name === 'srcset') ? el[OFI].img : el;
		}
		if (!supportsObjectPosition) {
			HTMLImageElement.prototype.getAttribute = function (name) {
				return nativeGetAttribute.call(getOfiImageMaybe(this, name), name);
			};

			HTMLImageElement.prototype.setAttribute = function (name, value) {
				return nativeSetAttribute.call(getOfiImageMaybe(this, name), name, String(value));
			};
		}
	}

	function fix(imgs, opts) {
		var startAutoMode = !autoModeEnabled && !imgs;
		opts = opts || {};
		imgs = imgs || 'img';

		if ((supportsObjectPosition && !opts.skipTest) || !supportsOFI) {
			return false;
		}

		// use imgs as a selector or just select all images
		if (imgs === 'img') {
			imgs = document.getElementsByTagName('img');
		} else if (typeof imgs === 'string') {
			imgs = document.querySelectorAll(imgs);
		} else if (!('length' in imgs)) {
			imgs = [imgs];
		}

		// apply fix to all
		for (var i = 0; i < imgs.length; i++) {
			imgs[i][OFI] = imgs[i][OFI] || {
				skipTest: opts.skipTest
			};
			fixOne(imgs[i]);
		}

		if (startAutoMode) {
			document.body.addEventListener('load', function (e) {
				if (e.target.tagName === 'IMG') {
					fix(e.target, {
						skipTest: opts.skipTest
					});
				}
			}, true);
			autoModeEnabled = true;
			imgs = 'img'; // reset to a generic selector for watchMQ
		}

		// if requested, watch media queries for object-fit change
		if (opts.watchMQ) {
			window.addEventListener('resize', fix.bind(null, imgs, {
				skipTest: opts.skipTest
			}));
		}
	}

	fix.supportsObjectFit = supportsObjectFit;
	fix.supportsObjectPosition = supportsObjectPosition;

	hijackAttributes();

	return fix;

}());

//============================================
//	objectFitImages 設定
//  CSS
//	for ALL -- object-fit: cover;
//	for IE --  font-family: 'object-fit: cover;';
//============================================

$(function () {
	objectFitImages('.listing_box_thumb img');
});


//============================================
//	Detecting Browser & Device Ver.1.4.2
//============================================

//++++++++++++ モバイル/タブレット/ブラウザ判定処理 ++++++++++++

//ブラウザ/デバイス 種別
var isMobile = false,
	isTablet = false,
	isRetina = false,
	isMobileTablet = false,
	isAndroid = false;

//モバイル　タブレット　判定
//https://w3g.jp/blog/js_browser_sniffing2015
var _ua = (function (u) {
	return {
		Tablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1) ||
			u.indexOf("ipad") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") == -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) ||
			u.indexOf("kindle") != -1 ||
			u.indexOf("silk") != -1 ||
			u.indexOf("playbook") != -1,
		Mobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1) ||
			u.indexOf("iphone") != -1 ||
			u.indexOf("ipod") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") != -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) |
			u.indexOf("blackberry") != -1
	};
})(window.navigator.userAgent.toLowerCase());

//モバイル
if (_ua.Mobile) {
	isMobile = true;
	isMobileTablet = true;
}
//タブレット
if (_ua.Tablet) {
	isTablet = true;
	isMobileTablet = true;
}

//Retina Display 判定
if (window.retina || window.devicePixelRatio > 1) {
	isRetina = true;
}

//Android 判定
if (/android[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
	isAndroid = true;
}




//============================================
//	デバイス　ブラウザ別の処理
//============================================

//++++++++++++ モバイル/タブレット クラス追加 ++++++++++++

$(function () {
	if (isMobile) {
		//HTMLにclass isMobileを追加
		$('html').addClass('isMobile');
		$('html').addClass('isMobileTablet');
	} else if (isTablet) {
		//HTMLにclass isTabletを追加
		$('html').addClass('isTablet');
		$('html').addClass('isMobileTablet');
	}
	//Retina Display isRetina クラス追加
	if (isRetina) {
		$('html').addClass('isRetina');
	}
});


//============================================
//	Veiwport 切り替え Tablet
//============================================

$(function () {

	//参考 iPad /iPad Mini 768x1024
	//https://qiita.com/tomohisaota/items/f8857d01f328e34fb551
	/*	var TABLET_WIDTH = 768;
		//ウィンドウ幅
		var window_width = window.innerWidth;
		alert(window_width);

		if ((window_width >= TABLET_WIDTH) || (isTablet)) {
			var metalist = document.getElementsByTagName('meta');
			for (var i = 0; i < metalist.length; i++) {
				var name = metalist[i].getAttribute('name');
				if (name && name.toLowerCase() === 'viewport') {
					metalist[i].setAttribute('content', 'target-densitydpi=device-dpi, width=1040, user-scalable=yes');
					break;
				}
			}
		}*/

});



//============================================
//	汎用スクリプト 
//============================================


//++++++ Copyright ++++++
// Copyright <span class="copyYear">&copy;</span>

$(function () {
	var date = new Date();
	var copyYear = date.getFullYear();
	copyYear = "&copy; " + "1995-" + copyYear;
	$('.copyYear').html(copyYear);
});





//++++++ Page Anc Scroll ++++++
/*$(function () {
	$(document).on("click", "a[href*=#],area[href*=#]", function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
			location.hostname == this.hostname) {
			var $target = $(this.hash);
			$target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
			if ($target.length) {
				var targetOffset = $target.offset().top - 20;
				$('html,body').animate({
					scrollTop: targetOffset
				}, 800);
				return false;
			}
		}
	});
});*/


//============================================
//	アンカーリンク　スクロール
//============================================

$(function () {

	var TABLET_WIDTH = 768;
	//ウィンドウ幅
	var window_width = window.innerWidth;


	var nav_height = 80;
	if (window_width >= TABLET_WIDTH) {
		nav_height = 80;
	}


	var target_parm = '';
	var match = location.search.match(/target=(.*?)(&|$)/);
	if (match) {
		target_parm = decodeURIComponent(match[1]);
		target_parm = '#' + target_parm;
	}
	if (target_parm.length) {
		setTimeout(function () {
			var targetOffset = $(target_parm).offset().top - nav_height;
			$('html,body').animate({
				scrollTop: targetOffset
			}, 800);
			return false;
		}, 500);
	}

});

//============================================
//	ショップリンク
//============================================
$(function () {
	$('.js-shop-link').on('click', function () {
		$('.pop_container').fadeIn('fast');

		var window_height = $(window).height();
		window_height = Number(window_height);
		$('.pop_container').css('height', window_height + 'px');

		var shop_title = $(this).attr('data-title');
		var shop_r = $(this).attr('data-r');
		var shop_d = $(this).attr('data-d');

		$('.pop_window h3').text(shop_title);
		$('.pop_window a.shop_r').attr('href', shop_r);
		$('.pop_window a.shop_d').attr('href', shop_d);

		if (shop_d.match('shop/details/premium')) {
			$('.pop_window a.shop_d').html('店舗詳細<span>(外部サイト)</span>');
			$('.pop_window a.shop_d').attr('target', '_blank');
		} else {
			$('.pop_window a.shop_d').html('店舗詳細');
			$('.pop_window a.shop_d').attr('target', '_self');
		}


	});
	$('.pop_container').on('click', function () {
		$(this).fadeOut('fast');

	});
});

//============================================
//	モバイル　ナビ
//============================================

$(function () {

	$('.js-mobile_navi').on('click', function () {
		$('.mobile_navi').fadeIn('fast');
		$('body').addClass('non_scroll');
		$('html').addClass('non_scroll');

		var window_height = $(window).height();
		window_height = Number(window_height);

		$('.mobile_navi').css('height', window_height + 'px');


	});
	$('.mobile_navi_close').on('click', function () {
		$('.mobile_navi').fadeOut('fast');
		$('body').removeClass('non_scroll');
		$('html').removeClass('non_scroll');

	});




	var window_height = $(window).height();
	window_height = Number(window_height);

	$('.mobile_navi').css('height', window_height + 'px');

	$(window).resize(function (event) {
		var window_height = $(window).height();
		window_height = Number(window_height);
		$('.mobile_navi').css('height', window_height + 'px');
	});

	$('.mn_mid-category').click(function () {
		$('.mn_category').fadeToggle();
		if ($(this).hasClass('icon_to_close')) {
			$(this).removeClass('icon_to_close');
		} else {
			$(this).addClass('icon_to_close');
		}

	});
	$('.mn_mid-info').click(function () {
		$('.mn_info').fadeToggle();
		if ($(this).hasClass('icon_to_close')) {
			$(this).removeClass('icon_to_close');
		} else {
			$(this).addClass('icon_to_close');
		}

	});


});




//============================================
//	グローバルナビ　現在地
//============================================

$(function () {

	//URLを取得
	var my_path = location.pathname;


	//カテゴリごとの変数
	var is_home, is_nav1, is_nav2, is_nav3, is_nav4, is_nav5, is_nav6, is_nav7 = false;

	//@@@@@@ カテゴリごとのURL規則 @@@@@

	//*** nav1 ***/
	var regex_nav1 = /^\/about\//i;

	//*** nav2***/
	var regex_nav2 = /^\/special\//i;

	//*** nav3 ***/
	var regex_nav3 = /^\/topics\//i;

	//*** nav4 ***/
	var regex_nav4 = /^\/facility\//i;

	//*** nav5 ***/
	var regex_nav5 = /^\/blog\//i;

	//*** nav6 ***/
	var regex_nav6 = /^\/access\//i;

	//*** nav7 ***/
	//var regex_nav7 = /^\/access\//i;

	//*** HOME ***/
	//var regex_home = ($('body').attr('id') == 'root');
	var regex_home = ($('body').hasClass('Index'));


	//@@@@@@ 条件をテスト @@@@@@

	if (my_path.match(regex_nav1)) {
		is_nav1 = true;
	} else if (my_path.match(regex_nav2)) {
		is_nav2 = true;
	} else if (my_path.match(regex_nav3)) {
		is_nav3 = true;
	} else if (my_path.match(regex_nav4)) {
		is_nav4 = true;
	} else if (my_path.match(regex_nav5)) {
		is_nav5 = true;
	} else if (my_path.match(regex_nav6)) {
		is_nav6 = true;
	} else if (my_path.match(regex_nav7)) {
		is_nav7 = true;
	} else if (regex_home) {
		is_home = true;
	}


	//@@@@@@ 条件にあったら　.activeを追加　@@@@@@

	//クラス activeを追加
	if (is_home) {
		$('.gnav00').addClass('active');
	} else if (is_nav1) {
		$('.gnav01').addClass('active');
	} else if (is_nav2) {
		$('.gnav02').addClass('active');
	} else if (is_nav3) {
		$('.gnav03').addClass('active');
	} else if (is_nav4) {
		$('.gnav04').addClass('active');
	} else if (is_nav5) {
		$('.gnav05').addClass('active');
	} else if (is_nav6) {
		$('.gnav06').addClass('active');
	} else if (is_nav7) {
		$('.gnav07').addClass('active');
	}


});


//============================================
//	グローバルナビ　表示　(Index)
//============================================

$(function () {

	if ($('body').hasClass('Index')) {
		$(window).scroll(function () {

			var window_width = window.innerWidth;
			if (window_width >= 768) {


				//var idx_nav_top = $('.idxNavi_contaner').position();
				var idx_nav_top = $('.gn').position().top;
				var scroll_top = $(window).scrollTop();
				if (scroll_top > idx_nav_top) {
					$('.Index header').slideDown();
					console.log('yes');
				}
				if (scroll_top < idx_nav_top) {
					$('header').hide();
				}
				//alert(idx_nav_top);
				console.log(scroll_top);
			}
		});
	}


});



//++++++ Page Anc Scroll ++++++
(function ($) {
	$(function () {
		$(document).on("click", "a[href*=#],area[href*=#]", function () {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
				location.hostname == this.hostname) {
				var $target = $(this.hash);
				$target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
				if ($target.length) {
					var targetOffset = $target.offset().top - 20;
					$('html,body').animate({
						scrollTop: targetOffset
					}, 800);
					return false;
				}
			}
		});
	});
})(jQuery);


$(function () {
	var showFlag = false;
	var topBtn = $('#scrollTop');
	topBtn.css('bottom', '-100px');
	var showFlag = false;
	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			if (showFlag == false) {
				showFlag = true;
				topBtn.stop().animate({
					'bottom': '16px'
				}, 200);
			}
		} else {
			if (showFlag) {
				showFlag = false;
				topBtn.stop().animate({
					'bottom': '-100px'
				}, 200);
			}
		}
	});
	//スクロールしてトップ
	topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});
});


$(function () {
	$('a[href^=#]').click(function () {
		var speed = 400;
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var headerHeight = 60; //固定ヘッダーの高さ
		var position = target.offset().top - headerHeight; //ターゲットの座標からヘッダの高さ分引く
		$('body,html').animate({
			scrollTop: position
		}, speed, 'swing');
		return false;
	});
});




$(function () {
	$('.item').matchHeight();
	$('.itemHeight').matchHeight();
});


$(function () {
	fade('.dropmenu');
});


function fade(elm) {
	var $fade = $(elm),
		$body = $('body');
	$fade.addClass('hide');

	$('.menu-btn').on('click', function () {
		$fade.toggleClass('hide').addClass('animation');
		$('.menu-btn').toggleClass('active');

		if ($fade.hasClass('hide')) {
			$body.removeClass('no-scroll').off('.noScroll');
		} else {


			$body.addClass('no-scroll').on('touchmove.noScroll', function (e) {
				e.preventDefault();
			});

			$body.on('touchmove.noScroll', function (e) {
				e.preventDefault();
			});

		}
	});
}







/* global */
$(function () {
	var Accordion = function (el, multiple) {
		this.el = el || {};
		this.multiple = multiple || false;

		var dropdownlink = this.el.find('.dropdownlink');
		dropdownlink.on('click', {
				el: this.el,
				multiple: this.multiple
			},
			this.dropdown);
	};

	Accordion.prototype.dropdown = function (e) {
		var $el = e.data.el,
			$this = $(this),
			//this is the ul.submenuItems
			$next = $this.next();

		$next.slideToggle();
		$this.parent().toggleClass('open');

		if (!e.data.multiple) {
			//show only one menu at the same time
			$el.find('.submenuItems').not($next).slideUp().parent().removeClass('open');
		}
	}

	var accordion = new Accordion($('.accordion-menu'), false);
})





/* facility */
$(function () {

	var Accordion = function (el, multiple) {
		this.el = el || {};
		this.multiple = multiple || false;
		var dropdownlink = this.el.find('.subTitle');
		dropdownlink.on('click', {
				el: this.el,
				multiple: this.multiple
			},
			this.dropdown);
	};

	Accordion.prototype.dropdown = function (e) {
		var $el = e.data.el,
			$this = $(this),
			$next = $this.next();

		$next.slideToggle();
		$this.parent().toggleClass('open');

		if (!e.data.multiple) {
			$el.find('.subMenus').not($next).slideUp().parent().removeClass('open');
		}
	}
	var accordion = new Accordion($('.subCategoryBox'), false);
})


/* facility_detail */
$(function () {
	var Accordion = function (el, multiple) {
		this.el = el || {};
		this.multiple = multiple || false;
		var dropdownlink = this.el.find('.moreDetail');
		dropdownlink.on('click', {
				el: this.el,
				multiple: this.multiple
			},
			this.dropdown);
	};

	Accordion.prototype.dropdown = function (e) {
		var $el = e.data.el,
			$this = $(this),
			$next = $this.next();

		$next.slideToggle();
		$this.parent().toggleClass('open');

		if (!e.data.multiple) {
			$el.find('.subDetail').not($next).slideUp().parent().removeClass('open');
		}
	}
	var accordion = new Accordion($('.subDetailBox'), false);
})






$(function () {
	$(document).ready(function () {
		$(".galleryBox").lightGallery({
			selector: '.galleryItem'
		});
	});
});




$(function () {
	var $grid = $('.grid'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.cell').length; i++) {
		emptyCells.push($('<div>', {
			class: 'cell is-empty'
		}));
	}
	$grid.append(emptyCells);
});



$(function () {
	//下から表示させる要素を指定
	var $pagetop = $('.modalBox');
	//一定量スクロールするまで非表示
	$pagetop.hide();

	$(window).on('scroll', function () {
		//スクロール位置を取得
		if ($(this).scrollTop() < 160) {
			//要素をスライド非表示
			$pagetop.slideUp('slow');
		} else {
			//要素をスライド表示
			$pagetop.slideDown('slow');
		}
	});

	animation();
	$(window).scroll(function () {
		animation();
	});
});



$(function () {
	$('.side_fixed').addClass('move');
	$(window).scroll(function () {
		$(".side_fixed").each(function () {
			var imgPos = $(this).offset().top;
			var scroll = $(window).scrollTop();
			var windowHeight = $(window).height();
			if (scroll > imgPos - windowHeight + windowHeight / 5) {
				$(this).find("a").removeClass('move');
			} else {
				$(this).find("a").addClass('move');
			}
		});
	});
});


$(function () {
	$('.conditional_search').addClass('move');
	$(window).scroll(function () {
		$(".conditional_search").each(function () {
			let imgPos = $(this).offset().top;
			let scroll = $(window).scrollTop();
			let windowHeight = $(window).height();
			if (scroll > imgPos - windowHeight + windowHeight / 5) {
				$(this).find("a").removeClass('move');
			} else {
				$(this).find("a").addClass('move');
			}
		});
	});
});



$(function () {
	let $body = $('body');
	$('.openModal').click(function () {
		$('#modalArea').fadeIn();
		$body.toggleClass('no-scroll');
	});
	$('#closeModal , #modalBg').click(function () {
		$('#modalArea').fadeOut();
		$body.removeClass('no-scroll');
	});
	// $('.sideBtn').click(function () {
	// 	$body.toggleClass('no-scroll');
	// });
});


$(function () {
	let $body = $('body');
	$('.openGuide').click(function () {
		$('#modalGuide').fadeIn();
		$body.addClass('no-scroll');
	});
	$('#closeGuideModal , #modalGuideBg').click(function () {
		$('#modalGuide').fadeOut();
		$body.removeClass('no-scroll');
	});
});



$(function () {
	// Search Toggle //
	$(".search-btn").click(function () {
		$(".introLink").toggleClass("show");
		$(".search_box").toggleClass("is_active");
	});
});




$('.sideBtn').on('click', function () {



	if ($(this).hasClass('active')) {
		$(this).removeClass('active');
		$('.drawer_contain').addClass('open');
		$('.drawer_contain').removeClass('open');
		$('.overlay_search').removeClass('open');
	} else {
		$(this).addClass('active');
		$('.drawer_contain').addClass('open');
		$('.overlay_search').addClass('open');
	}
});
$('.sideBtn').on('click', function () {
	if ($(this).hasClass('open')) {
		$(this).removeClass('open');
		$('.drawer_contain').removeClass('open');
	}
});